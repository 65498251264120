import React from 'react';
import './Footer.css';


class Footer extends React.Component {
  render() {
    return (
      <div className="Footer">
        <p style={{float: 'left', margin: '10px'}}>© Copyright 2020 - Sven Andabaka</p>
        <p style={{float: 'right', margin: '10px'}}>Icons erstellt von <a href="https://www.flaticon.com/de/autoren/srip" title="srip">srip</a></p>
      </div>
    );
  }
}

export default Footer;