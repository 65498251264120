import React from 'react';
import './Navbar.css';

import logo from '../images/logo.svg';
import Contacts from './Contacts'

import Nav from 'react-bootstrap/Nav'
import Typing from 'react-typing-animation';

class MyNavbar extends React.Component {
  render() {
    return (
      <div>
        <div className="Logo">
          <img src={logo} alt="Logo"/>
        </div>
        <div className="Title">
          <h2>Sven Andabaka</h2>
        </div>
        <div>
          <Typing loop={true}>
            <span>I'm a CS student from Munich.</span>
            <Typing.Backspace count={24} delay={2000}/>
            <span>n open-minded person.</span>
            <Typing.Backspace count={22} delay={2000}/>
            <span>always open for new adventures.</span>
            <Typing.Backspace count={35} delay={2000}/>
            <span> would love to meet you.</span>
            <Typing.Backspace count={26} delay={2000}/>
            <Typing.Reset/>
          </Typing>
        </div>
        <div className="Nav">
          <Nav className="flex-column">
            <Nav.Link href="#Welcome" >Welcome</Nav.Link>
            <Nav.Link href="#Projects" eventKey="link-1">Projects</Nav.Link>
            <Nav.Link href="#Skills" eventKey="link-2">Skills</Nav.Link>
            <Nav.Link href="#Timeline"eventKey="disabled">Timeline</Nav.Link>
          </Nav>
        </div>
        <Contacts/>
      </div>
    )
  }
}

export default MyNavbar;