import React from 'react';
import './Timeline.css';
import { ReactComponent as EduIcon } from '../images/edu.svg'
import { ReactComponent as WorkIcon } from '../images/work.svg'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function TimelineElement({date, icon, Title, SubTitle, Text}) {
  return(
    <VerticalTimelineElement
      className="Timeline-Root"
      contentStyle={{background: '#fff', borderTop: '7px solid  #478bad', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
      contentArrowStyle={{ borderRight: '7px solid', color: '#478bad'}}
      date={date}
      iconStyle={{ background: '#478bad', fill: '#fff', boxShadow: '0 0 0 4px #fff, 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}
      icon={icon}
    >
      <h5>{Title}</h5>
      <h6>{SubTitle}</h6>
      <p>{Text}</p>
    </VerticalTimelineElement>
  );
}

class Timeline extends React.Component {
  render() {
    return (
      <div id="Timeline" className="Timeline">
        <h1>Timeline</h1>

        <VerticalTimeline className={ 'Timeline-Root' }>
          <TimelineElement 
            date='Nov 2020 - Present' 
            icon={<EduIcon/>}
            Title='Technical University of Munich (TUM)'
            SubTitle='M.Sc. Computer Science'
            Text=''
          />
          <TimelineElement 
            date='Jun 2020 - Present' 
            icon={<WorkIcon/>}
            Title='Quartett Mobile GmbH'
            SubTitle='iOS Developer'
            Text=''
          />
          <TimelineElement 
            date='Oct 2016 - Oct 2020' 
            icon={<EduIcon/>}
            Title='Technical University of Munich (TUM)'
            SubTitle='B.Sc. Computer Science'
            Text=''
          />
          <TimelineElement 
            date='Jan 2019 - Dez 2019' 
            icon={<WorkIcon/>}
            Title='Raytion GmbH'
            SubTitle='Internship / Working Student as Junior Software Engineer'
            Text=''
          />
          <TimelineElement 
            date='Jul 2018 - Dez 2018' 
            icon={<EduIcon/>}
            Title='Queensland University of Technology (QUT)'
            SubTitle='B.Sc. Informatics'
            Text='Exchange Semester'
          />
          <TimelineElement 
            date='Aug 2008 - Aug 2016' 
            icon={<EduIcon/>}
            Title='Görres-Gymnasium Düsseldorf'
            SubTitle='Primary and Higher Education'
            Text=''
          />
        </VerticalTimeline>
      </div>
    )
  }
}

export default Timeline;