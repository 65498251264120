import React from 'react';
import './Navbar.css';

import { ReactComponent as Devpost } from '../images/devpost.svg'
import { ReactComponent as Instagram } from '../images/instagram.svg'
import { ReactComponent as Github } from '../images/github.svg'
import { ReactComponent as LinkedIn } from '../images/linkedin.svg'
import { ReactComponent as Mail } from '../images/mail.svg'

class Contacts extends React.Component {
  render() {
    return (
      <div className="Contact">
        <a target="_blank" rel="noopener noreferrer" href={"https://github.com/sven0311"}><Github/></a>
        <a target="_blank" rel="noopener noreferrer" href={"https://devpost.com/svenandabaka"}><Devpost/></a>
        <a target="_blank" rel="noopener noreferrer" href={"https://www.linkedin.com/in/sven0311"}><LinkedIn/></a>
        <a target="_blank" rel="noopener noreferrer" href={"mailto:sven@andabaka.com"}><Mail/></a>
        <a target="_blank" rel="noopener noreferrer" href={"https://www.instagram.com/sven0311/"}><Instagram/></a>
      </div>
    );
  }
}

export default Contacts;