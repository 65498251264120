import React from 'react';
import Navbar from './component/Navbar';
import Projects from './component/Projects';
import Skills from './component/Skills';
import About from './component/About';
import Timeline from './component/Timeline';
import Footer from './component/Foooter';
import Contacts from './component/Contacts'
import './App.css';

import Sidebar from "react-sidebar";

const mql = window.matchMedia(`(min-width: 800px)`);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  render() {
    let contact;

    console.log(this.sidebarDocked)

    if (!this.state.sidebarDocked) {
      contact = <Contacts/>;
    }


    return (
      <div className="App">
        <Sidebar
          sidebar={<Navbar></Navbar>}
          open={this.state.sidebarOpen}
          docked={this.state.sidebarDocked}
          onSetOpen={this.onSetSidebarOpen}
          touch={false}
          sidebarClassName={'Navigation'}
        >
          <About/>
          <div className="Main">
            <Projects/>
            <Skills/>
            <Timeline/>
            {contact}
          </div>
          <Footer/>
        </Sidebar>
      </div>
    )
  }
}

export default App;
