import React from 'react';
import './About.css';

class About extends React.Component {
  render() {
    return (
      <div id="Welcome" className="Page">
        <div className="Welcome">
          <div className="Title">
            <h1>Hey! I'm Sven</h1>
          </div>
          <p>Below you can find some of my work</p>
        </div>
      </div>
    )
  }
}

export default About;