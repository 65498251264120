import React from 'react';
import './Projects.css';

import baynb from '../images/baynb.png'
import ShortQuts from '../images/ShortQuts.png'
import FoodRadar from '../images/FoodRadar.png'
import hearo from '../images/hearo.png'
import OnlineIDE from '../images/OnlineIDE.png'


import Card from 'react-bootstrap/Card';
import CardColumns from 'react-bootstrap/CardColumns';
import Badge from 'react-bootstrap/Badge'

class Projects extends React.Component {
  render() {
    return (
      <div id="Projects" className="Projects">
        <h1>Projects</h1>

        <div className="Card">
        <CardColumns>
          <Card style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
            <Card.Img style={{width: '80%'}} bsPrefix='cardImage' variant="top" src={OnlineIDE} />
            <Card.Body>
              <Card.Title>Online IDE</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">TUM Advanced Topics of Software Engineering</Card.Subtitle>
              <Card.Text>
                Developed an Online IDE for java and C. Users can login using the TUM Gitlab authentication. Users are able to create different projects. Each project can include multiple files and can be shared with other users.     
              </Card.Text>
              <Badge variant="secondary">Spring</Badge>{' '}
              <Badge variant="secondary">Angular</Badge>{' '}
              <Badge variant="secondary">Docker</Badge>{' '}
              <Badge variant="secondary">Microservices</Badge>{' '}
              <Badge variant="secondary">CI/CD</Badge>{' '}
            </Card.Body>
          </Card>
          <Card style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
            <Card.Img style={{width: '80%'}} bsPrefix='cardImage' variant="top" src={ShortQuts} />
            <Card.Body>
              <Card.Title>ShortQuts</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">TUM IPraktikum</Card.Subtitle>
              <Card.Text>
                An App for Quartett-Mobile GmbH which makes connected car functions available in the iOS Shortcuts app. 
                This enables a user to create custom workflows that include car actions, actions from Apple's apps,
                 and actions provided by any third party app, all working together by providing input and output to each other.              
              </Card.Text>
              <Badge variant="secondary">Swift</Badge>{' '}
              <Badge variant="secondary">SwiftUI</Badge>{' '}
            </Card.Body>
          </Card>
          <Card style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
            <Card.Img style={{width: '70%'}} variant="top" src={FoodRadar} />
            <Card.Body>
              <Card.Title>FoodRadar</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">QUT Mobile App Development</Card.Subtitle>
              <Card.Text>
                Developed an app that helps you find the best restaurant to your specific cuisine or meal preferences.
                This way you can not just see general info about restaurant, but also directly the menu 
                and also ratings to specific meals.
              </Card.Text>
              <Badge variant="secondary">C#</Badge>{' '}
              <Badge variant="secondary">Xamarin</Badge>{' '}
            </Card.Body>
            <Card.Link href="https://github.com/sven0311/Foodradar">Github</Card.Link>
          </Card>
          <Card style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
            <Card.Img style={{width: '50%'}} variant="top" src={baynb} />
            <Card.Body>
              <Card.Title>Bay'n'B</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">HackaTUM 2019</Card.Subtitle>
              <Card.Text>
                Developed an app that takes over the whole process of booking a trip. 
                It suggests accommodations to you, a form of transportation and possible 
                activities for your given preferences. You can then book those with just a few clicks.
                Thus, we replace travel agencies and make the whole process of booking a trip a lot easier.
              </Card.Text>
              <Badge variant="secondary">Swift</Badge>{' '}
            </Card.Body>
            <Card.Link href="https://github.com/sven0311/hackatum2019">Github</Card.Link>
          </Card>
          <Card style={{boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
            <Card.Img style={{width: '80%'}} bsPrefix='cardImage' variant="top" src={hearo} />
            <Card.Body>
              <Card.Title>HeARo</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Winner Accenture Campus Innovation Challenge 2018</Card.Subtitle>
              <Card.Text>
              Project heARo - an app for AR glasses that uses speech-to-text transcription to offer subtitles for the real 
              world to improve the living quality of deaf people. Our team consisting of five students was able to assert 
              itself in three rounds against competing teams from Germany, Austria, and Switzerland.              
              </Card.Text>
              <Badge variant="secondary">Java</Badge>{' '}
            </Card.Body>
            <Card.Link href="https://www.accenture.com/de-de/careers/campus-challenge">More info</Card.Link>
          </Card>
        </CardColumns>
        </div>
      </div>
    )
  }
}

export default Projects;