import React from 'react';
import './Skills.css';

import VisibilitySensor from "react-visibility-sensor";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function ProgressCircle(props) {
  return (
    <VisibilitySensor>
      {({ isVisible }) => {
        return (
          <CircularProgressbar 
            className="Circle"
            value={isVisible ? props.value.rank : 0}
            text={`${props.value.desc}`} 
            strokeWidth={5}
            styles={buildStyles({
              textColor: "#478bad",
              pathColor: '#478bad',
              textSize: "16px"
            })}
          />
        );
      }}
    </VisibilitySensor>
  );
}

class Skills extends React.Component {
  render() {
    return (
      <div id="Skills" className="Skills">
        <h1>Skills</h1>

        <h3>Programming</h3>

        <div className="Circles">
          <ProgressCircle 
            value={{
              rank: 80,
              desc: 'Java',
            }} 
          />
          <ProgressCircle 
            value={{
              rank: 70,
              desc: 'Swift',
            }} 
          />
          <ProgressCircle 
            value={{
              rank: 60,
              desc: 'React, JS',
            }} 
          />
          <ProgressCircle 
            value={{
              rank: 40,
              desc: 'HTML, CSS',
            }} 
          />
          <ProgressCircle 
            value={{
              rank: 40,
              desc: 'SQL',
            }} 
          />
        </div>

        <h3>Languages</h3>

        <div className="Circles">
          <ProgressCircle 
            value={{
              rank: 100,
              desc: 'German',
            }} 
          />
          <ProgressCircle 
            value={{
              rank: 100,
              desc: 'Croatian',
            }} 
          />
          <ProgressCircle 
            value={{
              rank: 80,
              desc: 'English',
            }} 
          />
          <ProgressCircle 
            value={{
              rank: 30,
              desc: 'Latin',
            }} 
          />
        </div>
      </div>
    )
  }
}

export default Skills;